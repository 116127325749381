import React,{useState, useEffect} from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

import ImageUpload from "../../../Hazards/components/imageUpload";

import {
    makeSelectInductionImage,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";


import {
    uploadInductionImageReq
  } from "store/supervisor/actions";


import { createStructuredSelector } from "reselect";

import { connect } from "react-redux";

const VREditorForm = ({
page,
updatePageData, 
induction_id,
setEditData,

inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
}) => {

    const [scenes, setScenes] = useState(page?.data?.vr);
    const [initialState, setInitialState] = useState(true);
    const [selectedScene, setSelectedScene] = useState(0);

    const updateVRScene = (item, changes) => {
    
        var updatedScenes = [...scenes];
        updatedScenes[item] = {...updatedScenes[item], ...changes};
        setScenes(updatedScenes);
        updatePageData({key:'vr',value:updatedScenes},false); //update real data
    }

    const createVRScene = (item) => {
    
        var updatedScenes = [...scenes];
        updatedScenes.push(item);
        setScenes(updatedScenes);
        updatePageData({key:'vr',value:updatedScenes},false); //update real data
    }


    useEffect(() => {
        
        if(initialState)
          return;
    
        if (inductionImage?.url) 
        {
           setEditData(true);
           updateVRScene(selectedScene,{'scene_image': inductionImage?.url, 'scene_image_id': inductionImage?.id});
        }
   }, [inductionImage]);


  return <div>
        
        <h4 className={classes.formHeading}>VR Scenes</h4>

        {scenes?.map((vrItem,vIndex) =>
         <>
            <br />
            <h5>Scene {vIndex + 1}</h5>
            <Input
                hasTooltip={true}
                order="column"
                value={vrItem?.scene_image}
                name={"Image URL"}
                characterLimit={50}
                onChange={(e) => {
                    //updateVRScene(vIndex,{scene_image: e.target.value});
                }}
                label={"360 Scene Image URL"}
                placeholder={"https://...."}
                error={""}
                tooltipText={
                "URL for the 360 image"
                }
            />  

            <ImageUpload
                isSuccess={inductionImageSuccess}
                isUploading={inductionImageLoading}
                isError={inductionImageError}
                onDelete={() => {
                //setFieldValue("image_url", null);
                //setChangeCount((val) => val + 1);
                }}
                imageUrl={vrItem?.scene_image}
                description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                onLoad={(event) => {
                if (event.target.files[0]) {
                    
                    setInitialState(false);
                    setSelectedScene(vIndex);
                    uploadInductionImage({
                        image: event.target.files[0],
                    });
                    
                }
                }}
            />


            <br />
            <button
                    id={"preview_scene"}
                    type="submit"
                    style={{width: '260px'}}
                    onClick={function(){
                        window.open('vr/?is_preview=1&page=' + page.id + '&scene=' + vIndex, '_blank').focus();
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    Preview scene
                </button>

        </>
        )}
        

        <br />
        <br />
            <button
                    id={"create_scene"}
                    type="submit"
                    style={{width: '260px'}}
                    onClick={function(){
                        const newID = scenes.length + 1;
                        createVRScene({
                            url: "/vr/",
                            image: "https://keepsafe.work/images/03.02_vr_B.jpg",
                            scene_image: "VR_Scene_1.jpg",
                            button_id: 'induction.' + induction_id + '.page.' + page?.id + '.vr_button.' + newID
                        });
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    Create a new scene
                </button>



  </div>;
};

const mapStateToProps = createStructuredSelector({
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
        uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(VREditorForm);